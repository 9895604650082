// import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser } from "@remix-run/react";
import { startTransition } from "react";
import { hydrateRoot } from "react-dom/client";

// Sentry.init({
//     dsn: "https://1cdb95cd2117159ebaaff2df7b8debfa@o4506101699051520.ingest.us.sentry.io/4508830556946432"
// })

startTransition(() => {
  hydrateRoot(
    document,
    <RemixBrowser />
  );
});
